import {
  getIsPFMLASOBenefitType,
  getIsPFMLBenefitType,
  getIsStatutoryBenefitType,
} from "shared/types/BenefitTypes";
import { type Level, type PFMLOptions, type Plan } from "shared/types/Plan";
import {
  benefitTypeToCoverage,
  coverageToBenefitType,
  getIsCoverageNotIncludedInSection125Plan,
} from "shared/types/SlfCoverages";
import { getIsSubStepApplicable } from "shared/utils/EIF/getIsSubStepApplicable";
import type { YesNo } from ".prisma/client";
import type {
  Client as DBClient,
  Policy as DBPolicy,
  IssueOrAttainedAge,
  Tabacco,
} from "@prisma/client";
import type {
  BenefitTypeEIF,
  NonConfigurationBenefitType,
  PFMLASOBenefitType,
} from "shared/types/BenefitTypes";
import type { AgeRules, Client } from "shared/types/Client";
import type { SlfCoverageLongName } from "shared/types/SlfCoverages";
import type { ClientFeatureToggles } from "shared/types/Toggles";
import type { UserId } from "shared/types/User";

export const eifStepIdMap = {
  "company-information": "Company Details",
  "plan-administrators-&-billing": "Plan Administrators & Billing",
  "plan-configuration-&-eligibility": "Plan Configuration & Eligibility",
  "other-contract-details": "Other Contract Details",
  "review-&-submit": "Review & Submit",
} as const;
export type EIFStepId = keyof typeof eifStepIdMap;
export const eifStepIds = Object.keys(eifStepIdMap).filter(isEIFStepId);

export const companyInformationSubStepMap = {
  "benefits-administration-and-data-feed": "Benefits administration & data feeds",
  "tax-id": "Company Tax ID Number",
  "subsidiaries-and-affiliates": "Subsidiaries & affiliates",
  "current-sun-life-policies": "Current Sun Life coverage information",
  "previous-coverage-information": "Previous coverage information",
} as const;
export type EIFCompanyDetailsSubStep = keyof typeof companyInformationSubStepMap;

export const planAdministratorsAndBillingSubStepMap = {
  "plan-administrators": "Plan Administrators",
  "billing-preferences": "Billing Preferences",
  "claims-check-mailing-locations": "Claims Check Mailing Locations",
  "monthly-claims-reports-and-eobs": "Monthly Claims Reports and EOBs",
  "malpractice-reimbursements": "Malpractice Reimbursements",
} as const;
export type EIFPlanAdministratorsAndBillingSubStepMap =
  keyof typeof planAdministratorsAndBillingSubStepMap;

export const planConfigEligibilityOnlyPFMLSubStepMap = {
  "class-builder": "Eligible employee groups",
  "non-class-benefits-preferences": "PFML preferences",
  "fli-preferences": "Family Leave Insurance preferences",
} as const;
export type EIFPlanConfigEligibilityOnlyPFMLSubStep =
  keyof typeof planConfigEligibilityOnlyPFMLSubStepMap;

export const planConfigEligibilityOnlyStatutorySubStepMap = {
  "class-builder": "Eligible employee groups",
  "non-class-benefits-preferences": "Statutory benefits",
  "fli-preferences": "Family Leave Insurance preferences",
} as const;
export type EIFPlanConfigEligibilityOnlyStatutorySubStep =
  keyof typeof planConfigEligibilityOnlyStatutorySubStepMap;

export const planConfigEligibilityWithPFMLAndStatutorySubStepMap = {
  "class-builder": "Eligible employee groups",
  "non-class-benefits-preferences": "PFML & Statutory benefits",
  "fli-preferences": "Family Leave Insurance preferences",
} as const;
export type EIFPlanConfigEligibilityWithPFMLAndStatutorySubStep =
  keyof typeof planConfigEligibilityWithPFMLAndStatutorySubStepMap;

export const otherContractDetailsSubStepMap = {
  "confirm-policy-anniversary": "Confirm policy anniversary",
  "additional-waiting-period-rules": "Additional waiting period rules",
  "rehire-provision": "Rehire provision",
  "union-members-and-domestic-partners": "Union members and domestic partners",
  "changes-during-annual-enrollment": "Changes during annual enrollment",
  "termination-of-insurance": "Termination of insurance",
  "age-rules": "Age rules",
  "other-changes-to-coverage": "Other changes to coverage",
  "employee-certificate": "Employee certificate",
  "section-125": "Section 125",
  erisa: "ERISA",
  "value-added-services": "Value-added services",
} as const;
export type EIFOtherContractDetailsSubStep = keyof typeof otherContractDetailsSubStepMap;

// remove this ?
export const reviewAndSubmitSubStepMap = {} as const;
export type EIFReviewAndSubmitSubStep = keyof typeof reviewAndSubmitSubStepMap;

export type EIFSubStepId =
  | EIFCompanyDetailsSubStep
  | EIFPlanAdministratorsAndBillingSubStepMap
  | EIFPlanConfigEligibilityOnlyPFMLSubStep
  | EIFOtherContractDetailsSubStep
  | EIFReviewAndSubmitSubStep;

export const eifSubStepMapWithoutPlanConfigEligibility: Record<
  Exclude<EIFSubStepId, EIFPlanConfigEligibilityOnlyPFMLSubStep>,
  string
> = {
  ...companyInformationSubStepMap,
  ...planAdministratorsAndBillingSubStepMap,
  ...otherContractDetailsSubStepMap,
  ...reviewAndSubmitSubStepMap,
};

// eslint-disable-next-line @typescript-eslint/consistent-type-assertions -- .
export const eifSubStepIds = Object.keys({
  ...companyInformationSubStepMap,
  ...planAdministratorsAndBillingSubStepMap,
  ...planConfigEligibilityWithPFMLAndStatutorySubStepMap,
  ...otherContractDetailsSubStepMap,
  ...reviewAndSubmitSubStepMap,
}) as EIFSubStepId[];

export const eifStepNavigation = {
  "company-information": companyInformationSubStepMap,
  "plan-administrators-&-billing": planAdministratorsAndBillingSubStepMap,
  "plan-configuration-&-eligibility": planConfigEligibilityOnlyPFMLSubStepMap,
  "other-contract-details": otherContractDetailsSubStepMap,
  "review-&-submit": reviewAndSubmitSubStepMap,
} as const;

export const eifSubStepsForStep = (
  step: EIFStepId,
  client: Client,
  clientPlans: Plan[],
  featureToggles: ClientFeatureToggles,
) => {
  // eslint-disable-next-line @typescript-eslint/consistent-type-assertions -- appropriate
  const relevantSubSteps = Object.keys(eifStepNavigation[step]) as EIFSubStepId[];

  const filteredSubSteps = relevantSubSteps.filter((eifSubStepId) =>
    getIsSubStepApplicable({ eifSubStepId, client, plans: clientPlans, featureToggles }),
  );
  return filteredSubSteps;
};

export function isEIFStepId(eifStepIdValue: string): eifStepIdValue is EIFStepId {
  const isEIFStepId = Object.keys(eifStepIdMap).includes(eifStepIdValue);
  return isEIFStepId;
}

export function isEIFSubStepId(value: string): value is EIFSubStepId {
  // eslint-disable-next-line @typescript-eslint/consistent-type-assertions -- .
  const isIt = eifSubStepIds.includes(value as EIFSubStepId);
  return isIt;
}

export function isEIFOtherContractDetailsSubStep(
  eifSubStepId: EIFSubStepId,
): eifSubStepId is EIFOtherContractDetailsSubStep {
  const isIt = Object.keys(otherContractDetailsSubStepMap).includes(eifSubStepId);
  return isIt;
}

export function isEIFCompanyDetailsSubStep(
  eifSubStepId: EIFSubStepId,
): eifSubStepId is EIFCompanyDetailsSubStep {
  const isCompanyInfoSubStep = Object.keys(companyInformationSubStepMap).includes(eifSubStepId);
  return isCompanyInfoSubStep;
}

export function isEIFPlanAdministratorsAndBillingSubStep(
  eifSubStepId: EIFSubStepId,
): eifSubStepId is EIFPlanAdministratorsAndBillingSubStepMap {
  const isIt = Object.keys(planAdministratorsAndBillingSubStepMap).includes(eifSubStepId);
  return isIt;
}

export function isEIFPCESubStep(
  eifSubStepId: EIFSubStepId,
): eifSubStepId is EIFPlanConfigEligibilityOnlyPFMLSubStep {
  const isPCESubStep = Object.keys(planConfigEligibilityOnlyPFMLSubStepMap).includes(eifSubStepId);
  return isPCESubStep;
}

export type PlanSetupInput = {
  // when a plan is being created it will not have an id, so we have to allow for undefined
  id: string | undefined;
  benefitType: Exclude<BenefitTypeEIF, NonConfigurationBenefitType>;
  level: Level;
  salaryBased: boolean | null;
  pfmlOption: PFMLOptions | null;
};

export type ClientSetupPlanConfigurationInput = {
  allowClientSelfServicePlanConfig: YesNo;
  allowClientToViewPlanConfig: boolean;
  plans: PlanSetupInput[];
  authorizedSignerUserId: UserId | null;
  outsideSigner: { firstName: string; lastName: string; email: string } | null;
  ageRules: AgeRules[] | null;
  allBenefitsNonContributory?: YesNo | null;
  criticalIllnessType?: IssueOrAttainedAge | null;
  criticalIllnessRateType?: Tabacco | null;
  changesForDependentLifeBenefitsUseAge?: YesNo | null;
};

export type ClientSetupPlanConfigurationOutput = {
  client: Client;
  addedPlansCount: number;
  removedPlansCount: number;
};

const isItDEIFField: Record<keyof DBClient, boolean> = {
  id: false,
  isActive: false,
  isTest: false,
  isBenefitsExplorer: false,
  isOnboard: false,
  isMaxwell: false,
  isArchived: false,
  implementationType: false,
  inForceSLCoverages: false,
  baseClientId: false,
  name: false,
  ticketId: false,
  caseId: false,
  employeesNumber: false,
  mvpBrokerTier: false,
  limitedEmails: false,
  eoiRequired: false,
  sLConnectCredentialSentDate: false,
  baRegisteredSLConnect: false,
  creCssEmailAddress: false,
  creCssName: false,
  territory: false,
  completedSetup: false,
  completedSetupAt: false,
  priorCarrierCertificationsStatus: false,
  NIGOReasons: false,
  jiraUpdatedAt: false,
  releaseStatus: false,
  postOnboardingSurveySent: false,
  VDRReviewed: false,
  kickoffCallSelection: false,
  kickoffCallSchedule: false,
  kickoffCallStatus: false,
  kickoffCallTopics: false,
  kickoffCallPeople: false,
  kickoffCallUpdatedAt: false,
  kickoffCallUpdatedBy: false,
  installCallSelection: false,
  installCallSchedule: false,
  installCallTopics: false,
  installCallPeople: false,
  installCallUpdatedAt: false,
  installCallUpdatedBy: false,
  enrollmentNeeds: false,
  enrollmentNeedsUpdatedAt: false,
  enrollmentNeedsUpdatedBy: false,
  upcomingEnrollment: false,
  firstPolicyEffective: false,
  enrollmentDateStart: false,
  enrollmentDateEnd: false,
  dontKnowEnrollmentEventDates: false,
  enrollmentEventUpdatedAt: false,
  enrollmentEventUpdatedBy: false,
  benAdminPlatformId_deprecated: false,
  benAdminPlatformOtherName_deprecated: false,
  benAdminPlatformUpdatedAt_deprecated: false,
  benAdminPlatformUpdatedBy_deprecated: false,
  hasBenAdminPlatform_deprecated: false,
  dataFeeds_deprecated: false,
  dataFeedsUpdatedAt_deprecated: false,
  dataFeedsUpdatedBy_deprecated: false,
  enrollmentSupport: false,
  streamlinedTechnology: false,
  valueAddedServices: false,
  billing: false,
  ongoingService: false,
  additionalSupport: false,
  otherTopicsToPayAttentionTo: false,
  otherTopicsToPayAttentionToText: false,
  productVariety: false,
  recommendationFromBroker: false,
  qualityCoverage: false,
  digitalCapabilities: false,
  service: false,
  cost: false,
  claimsManagement: false,
  mostImportantOther: false,
  mostImportantOtherText: false,
  setYourGoalsUpdatedAt: false,
  setYourGoalsUpdatedBy: false,
  slfImplementationConsultantId: false,
  ebrEmailAddress: false,
  issuedStateSitus: false,
  riskAcceptanceEmailSentDate: false,
  genericDentalIdCardEmailSentDate: false,
  personalizedDentalIdCardEmailSentDate: false,
  createdAt: false,
  updatedAt: false,
  deletedAt: false,
  deletedAtUnix: false,
  createdBy: false,
  updatedBy: false,
  deletedBy: false,
  liveReleaseStatusAt: false,
  digitalEIF: false,
  needsEmployeeClasses: true,
  didPrefillCompanyInformation: false,
  prefillCompanyInformation: false,
  alwaysShowBenefitSummaries: false,
  completedPaperworkReceivedOutsideOnboard: false,
  censusTaskHidden: false,
  ageRules: false,
  benefitsExplorerCustomBenefitOrder: false,
  benefitsExplorerCustomBranding: false,
  nonSunLifeBenefitsExplorer: false,
  healthcareProfessionalsSegment: false,
  pushedToCompassAt: false,

  dataFeedsImplementorContactId_deprecated: false,
  dataFeedsProductionSupportContactId_deprecated: false,
  dataFeedsBenAdminContactId_deprecated: false,
  webAdminContactId: false,
  limitDueDateEmails: false,

  activelyAtWorkUpdatedAt: false,
  activelyAtWorkUpdatedBy: false,

  /**
   * DEIF Fields
   */

  authorizedSignerUserId: true,
  brokerAsAuthorizedSigner: false,

  // Company information
  taxId: true,
  previouslyInsuredBySunLife: true,
  previousSlfPolicies: true,
  allowClientSelfServicePlanConfig: true,
  allowClientToViewPlanConfig: true,
  benefitsReplacedWithSlf: true,

  // Billing
  disabilityClaimsPreferencesDefaults: true,
  claimsCheckPreferencesDefaults: true,
  claimsCheckStepViewed: true,
  monthlyClaimsReportsAndEOBsDefaults: true,

  // Confirm Policy Anniversary
  policyAnniversaryMonth: true,

  // Additional waiting period rules
  unionMembersCovered: true,
  domesticPartnersCovered: true,
  timeSpentShouldCountTowardsWaitingPeriod: true,
  timeSpentAsPartTimeShouldCount: true,
  timeSpentAsOtherShouldCount: true,
  timeSpentAsOtherShouldCountText: true,
  waitingPeriodShouldBeWaived: true,

  // Employee Certificates
  shouldCertificatesBeSplitInAnotherWay: true,
  shouldCertificatesBeSplitInAnotherWayText: true,

  // EIF - rehire provisions
  reHireProvisionExists: true,
  reHireProvisionUnit: true,
  reHireProvisionDuration: true,
  rehireProvision: true,

  // Section 125
  hasSection125Plan: true,
  hasSection125Dental: true,
  hasSection125Vision: true,
  hasSection125DHMO: true,
  hasAdditionalCoverageIncludedIn125Plan: true,
  additonalCoverageIncludedInSection125Plan: true,

  // Age Rules
  ageReductionDecreaseTakeEffectWhen: true,
  ageBandIncreaseTakeEffectWhen: true,
  ageForDependentLifeBenefits: true,
  changesForDependentLifeBenefitsUseAge: true,

  // Other Changes to Coverage
  otherCoverageChangesTakeEffectWhen: true,
  firstOfTheFollowingMonthIncludesFirstDay: true,

  // Changes during annual enrollment
  whenDoYouAdministerYourAEP: true,
  whenDoYouAdministerYourAEPStartDate: true,
  whenDoYouAdministerYourAEPEndDate: true,
  whenDoAEPTakeEffect: true,
  whenDoAEPTakeEffectOther: true,
  whenDecreasesInsuranceTakeEffect: true,
  changesOnlyAllowedDuringAE: true,
  whenDoChangesToBenefitsTakeEffect: true,

  // ERISA
  erisaHasPlan: true,
  erisaPlanType: true,
  erisaPlanTypeOther: true,
  erisaPrefersPlanDetails: true,
  erisaAgentFirstName_deprecated: true,
  erisaAgentLastName_deprecated: true,
  erisaPlanAdminName: true,
  erisaStreetAddress: true,
  erisaCity: true,
  erisaState: true,
  erisaZipCode: true,
  erisaPlanNumber: true,
  erisaPlanNumberOther: true,
  erisaPlanYearEndDate_deprecated: true,
  erisaPlanYearEndDateMMDD: true,
  erisaAdministerOwnPlan: true,
  erisaHasAgentForLegalProcess: true,
  erisaIsLegalAgentSameAsAdmin: true,
  erisaLegalAgentCity: true,
  erisaLegalAgentName: true,
  erisaLegalAgentState: true,
  erisaLegalAgentStreetAddress: true,
  erisaLegalAgentZipCode: true,

  // Value added services
  valueAddedServicesOptions: true,
  valueAddedServicesStepViewed: true,

  // Review & Submit (Signing)
  eifSignedAt: true,
  eifSignedBy: true,
  eifOutsideSignedBy: true,
  eifBAReviewAndSubmitEmailSentDate: true,

  // Change Tracking
  // (the following can be updated after signing,
  // so we'll mark them as false here)
  deifChangesAcceptedAt: false,
  deifChangesAcceptedBy: false,
  deifChangesDeclinedAt: false,
  deifChangesDeclinedBy: false,
  deifChangesDeclinedReason: false,
  deifChangesOutsideAcceptedBy: false,
  deifChangesOutsideDeclinedBy: false,
  deifChangesReadyForReviewAt: false,
  deifChangesReadyForReviewBy: false,

  allEmployeesActivelyAtWork: false,
  allBenefitsNonContributory: false,

  malpracticeReimbursementRider: true,
  malpracticeReimbursementPayableTo: true,

  // PFML
  pfmlPreferencesStepViewed: true,

  // FLI
  fliPreferencesStepViewed: true,
  fliCoversAllEmployees: true,
  fliExcludedGroups: true,

  criticalIllnessType: false,
  criticalIllnessRateType: false,
  criticalIllnessDependentAge: true,
  criticalIllnessDependentTobacco: true,

  hasSubsidiary: true,
  subsidiaryEEsInNYNJOrHI: true,
  mainPolicyHolderEEsInNYNJOrHI: true,
  mainPolicyHolderEEStates: true,

  deductibleMaximumReportTask: false,
};

const isItDEIFFieldPolicy: Record<keyof DBPolicy, boolean> = {
  // Ben admin and data feeds
  hasBenAdminPlatform: true,
  benAdminPlatformId: true,
  benAdminPlatformOtherName: true,
  benAdminPlatformUpdatedAt: false,
  benAdminPlatformUpdatedBy: false,
  dataFeeds: true,
  dataFeedsUpdatedAt: false,
  dataFeedsUpdatedBy: false,

  // Billing Preferences
  billingAdministrationType: true,
  billingStructureType: true,
  billingSummaryStatementType: true,
  billPayrollCycles: true,
  billPayrollCyclesOther: true,
  billPayrollCyclesExplanation: true,
  billSplitType: true,
  whoSubmitsClaims: true,
  advanceOrArrears: true,

  // Nope
  id: false,
  clientId: false,
  ticketId: false,
  phaseId: false,
  ticketStatus: false,
  primaryPolicy: false,
  slfCoverages: false,
  slfCoveragesReady: false,
  slfPolicyNumber: false,
  enrollmentCensusDeadline: false,
  infoGatheringDeadline: false,
  approvedPFMLPlans: false,
  sunAdvisorSPDComplete: false,
  sunAdvisorAdvicePlusPreFundingAmount: false,
  frontloading: false,
  priorCarrierBill: false,
  jiraUpdatedAt: false,
  jiraCreatedAt: false,
  phaseChangeToInfoGatheringSent: false,
  phaseChangeToReviewAndValidateSent: false,
  phaseChangeToPlanApprovalSent: false,
  phaseChangeToContractsAndBillingSent: false,
  phaseChangeToLiveSent: false,
  riskAcceptanceEmailSent: false,
  dentalRolloverUpdatedEmailSent: false,
  riskAcceptanceEmailSentDate: false,
  genericDentalIdCardEmailSentDate: false,
  personalizedDentalIdCardEmailSentDate: false,
  liveStatusDate: false,
  connecticutUseSLFSPDForPFML: false,
  massachusettsUseSLFSPDForPFML: false,
  washingtonUseSLFSPDForPFML: false,
  coloradoUseSLFSPDForPFML: false,
  oregonUseSLFSPDForPFML: false,
  spdCompletedForPFML: false,
  massachusettsPFMLCheckCutting: false,
  connecticutPFMLCheckCutting: false,
  washingtonPFMLCheckCutting: false,
  coloradoPFMLCheckCutting: false,
  oregonPFMLCheckCutting: false,
  policyEffective: false,
  dontKnowEnrollmentEventDates: false,
  enrollmentEventUpdatedAt: false,
  enrollmentEventUpdatedBy: false,
  issuedStateSitus: false,
  dentalRollover: false,
  dentalRolloverCompleted: false,
  noDentalRollover: false,
  approvedForAdvancedBilling: false,
  createdAt: false,
  updatedAt: false,
  deletedAt: false,
  deletedAtUnix: false,
  createdBy: false,
  updatedBy: false,
  deletedBy: false,
};

export const clientDEIFFields = Object.entries(isItDEIFField)
  .filter(([_field, isIt]) => isIt)
  .map(([field]) => field)
  .filter(
    // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition -- type narrowing, we know that field is already of this type
    (_field): _field is keyof DBClient => true,
  );

export const policyDEIFFields = Object.entries(isItDEIFFieldPolicy)
  .filter(([_field, isIt]) => isIt)
  .map(([field]) => field)
  .filter(
    // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition -- type narrowing, we know that field is already of this type
    (_field): _field is keyof DBPolicy => true,
  );

export const getCurrentPFMLSelection = (plans: Plan[], allCoverages: SlfCoverageLongName[]) => {
  const availablePFMLBenefitTypes = allCoverages
    .map((coverage) => coverageToBenefitType[coverage])
    .filter(getIsPFMLASOBenefitType);

  const emptyPlanPFMLSelection = Object.fromEntries(
    availablePFMLBenefitTypes.map((pfmlASOBenefitType) => [pfmlASOBenefitType, null]),
  );

  const currentPFMLSelection = plans.reduce<
    Partial<Record<PFMLASOBenefitType, PFMLOptions | null>>
  >((acc, plan) => {
    if (getIsPFMLASOBenefitType(plan.benefitType)) {
      acc[plan.benefitType] = plan.pfmlOption;
    }

    return acc;
  }, emptyPlanPFMLSelection);

  return { availablePFMLBenefitTypes, currentPFMLSelection };
};

export const isAdditionalBenefitIncludedIn125Plan = (
  client: Client,
  benefitType: BenefitTypeEIF,
): boolean => {
  const coverageType = benefitTypeToCoverage[benefitType];
  if (!getIsCoverageNotIncludedInSection125Plan(coverageType)) {
    return false;
  }

  return Boolean(
    client.hasSection125Plan === "YES" &&
      client.hasAdditionalCoverageIncludedIn125Plan &&
      client.additonalCoverageIncludedInSection125Plan?.includes(coverageType),
  );
};

type GetEIFSubStepMapsArgs =
  | {
      eifSubStepId: Exclude<EIFSubStepId, "non-class-benefits-preferences">;
    }
  | {
      eifSubStepId: "non-class-benefits-preferences";
      clientPlans: Plan[];
    };

export function getEIFSubStepMap(args: GetEIFSubStepMapsArgs): string {
  switch (args.eifSubStepId) {
    case "non-class-benefits-preferences": {
      const hasStatutoryCoverage = args.clientPlans.some((plan) =>
        getIsStatutoryBenefitType(plan.benefitType),
      );
      const clientHasPFMLBenefitType = args.clientPlans.some((plan) =>
        getIsPFMLBenefitType(plan.benefitType),
      );
      const shouldShowStatutory = hasStatutoryCoverage;

      const pfmlOnly = clientHasPFMLBenefitType && !shouldShowStatutory;
      const statutoryOnly = !clientHasPFMLBenefitType && shouldShowStatutory;
      // const pfmlAndStatutory = clientHasPFMLBenefitType && shouldShowStatutory;

      return pfmlOnly
        ? {
            ...eifSubStepMapWithoutPlanConfigEligibility,
            ...planConfigEligibilityOnlyPFMLSubStepMap,
          }[args.eifSubStepId]
        : statutoryOnly
        ? {
            ...eifSubStepMapWithoutPlanConfigEligibility,
            ...planConfigEligibilityOnlyStatutorySubStepMap,
          }[args.eifSubStepId]
        : {
            ...eifSubStepMapWithoutPlanConfigEligibility,
            ...planConfigEligibilityWithPFMLAndStatutorySubStepMap,
          }[args.eifSubStepId];
    }
    default:
      return {
        ...eifSubStepMapWithoutPlanConfigEligibility,
        ...planConfigEligibilityOnlyPFMLSubStepMap,
      }[args.eifSubStepId];
  }
}
